import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateAdkit from "./CreateAdkit";

const CreateNewAdkitModal: React.FC<FacilityManagementModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.AdKitModal,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Create a new AdKit for ${currentFacility?.name}`}
      hideFooter={true}
      modalSize="w-full h-full md:w-2/3 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <CreateAdkit />
        </Row>
      }
    />
  );
};

type FacilityManagementModalProps = {
  title?: string;
  modalName: string;
};

CreateNewAdkitModal.defaultProps = {
  title: "Sim Management",
};

export default CreateNewAdkitModal;
