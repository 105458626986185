import { useState } from "react";
import { styles } from "./admin.styles";
import { FiEdit3 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { MdOutlineDragHandle } from "react-icons/md";
import { useFlags } from "launchdarkly-react-client-sdk";
import { modalAction } from "ui-modules/modals/modalSlice";
import { SimulatorDto, ValidationException } from "Models";
import { useFacilitySimulatorsPatch } from "modules/simulator/simulatorHooks";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import {
  getUserIdFromToken,
  useFacilitiesByUserId,
  useGetCurrentLocalFacilityId,
} from "modules/facility/facilityHooks";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import arrayMove from "array-move";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import toast from "react-hot-toast";

const SimManagementModal: React.FC<SimManagementModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const { liveScreenshots } = useFlags();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const simulators = useSelector(getSimulatorsByCurrentFacility);

  const userId = () => getUserIdFromToken();
  const [, , refreshFacilities] = useFacilitiesByUserId(userId());

  const [items, setItems] = useState(simulators);
  const [updateSims, setUpdateSims] = useState(false);

  const [
    updateFacilitySimulatorsQueryState,
    updateFacilitySimulators,
  ] = useFacilitySimulatorsPatch(currentFacilityId);

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.SimManagementModal,
      })
    );
  };

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
    setUpdateSims(true);
  };

  const handleSaveChanges = async () => {
    async function updateSimulators() {
      let payload: { id: string; name: string }[] = items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          screenShotOn: item.status.screenShotOn,
        };
      });

      let response = await updateFacilitySimulators(payload);

      if (response?.status === 207) {
        toast.custom(
          (t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-2xl w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Simulators have been updated successfully
                    </p>
                    <p className="mt-1 text-sm text-gray-500 hidden">
                      {response?.body?.errors.map((error: any) => {
                        return <p className="text-xs">{error.message}</p>;
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          ),
          { duration: 8000 }
        );
        updateOnSuccess();
      } else if (response?.status === 400) {
        let errors = response?.body?.errors as ValidationException[];
        toast.error(
          errors.map((e) => e.errors).join(", ") || "An error occurred"
        );
      } else if (response?.status === 200) {
        toast.success("Simulators have been updated successfully");
        updateOnSuccess();
      } else if (response?.status !== 200) {
        toast.error("An unexpected error occurred.");
      }
    }
    if (updateSims) {
      await updateSimulators();
    }
  };

  async function updateOnSuccess() {
    await refreshFacilities();
    await setUpdateSims(false);
  }

  const SimulatorItem = ({ item }: { item: SimulatorDto }) => {
    const [editName, setEditName] = useState(false);
    const [nameInvalid, setNameInvalid] = useState(false);
    const [simulatorName, setSimulatorName] = useState(item.name);

    const handleNameChange = () => {
      async function updateSimulatorName() {
        if (item.name !== simulatorName) {
          setItems((prevItems) => {
            return prevItems.map((sim) => {
              if (sim.id === item.id) {
                return { ...sim, name: simulatorName };
              }
              return sim;
            });
          });
          setUpdateSims(true);
        }
      }
      updateSimulatorName();
      setEditName(false);
    };

    const handleScreenshotChange = (checked: boolean) => {
      setItems((prevItems) => {
        return prevItems.map((sim) => {
          if (sim.id === item.id) {
            return {
              ...sim,
              status: {
                ...sim.status,
                screenShotOn: checked,
              },
            };
          }
          return sim;
        });
      });
      setUpdateSims(true);
    };

    function checkForDuplicateName(name: string) {
      return items.some((item) => item.name === name);
    }

    return (
      <div className="flex flex-1 flex-col">
        <div className="flex">
          {editName ? (
            <div className="flex justify-start items-center">
              <input
                type="text"
                className="border border-gray-300 h-6 m-0 px-2 w-auto rounded-md text-xs"
                placeholder={item.name}
                autoFocus
                onChange={(e) => setSimulatorName(e.target.value)}
                onKeyUp={(e) =>
                  setNameInvalid(checkForDuplicateName(e.currentTarget.value))
                }
              />
              {nameInvalid ? (
                <>
                  <p className="text-red-500 text-xs ml-2 pt-0.5">
                    Name already exists
                  </p>
                  <button
                    className={`${styles.editLinks} dark:text-zinc-900`}
                    onClick={() => setEditName(false)}
                  >
                    cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={`${styles.editLinks} dark:text-green-600`}
                    onClick={() => handleNameChange()}
                  >
                    save
                  </button>

                  <button
                    className={`${styles.editLinks} dark:text-zinc-900`}
                    onClick={() => setEditName(false)}
                  >
                    cancel
                  </button>
                </>
              )}
            </div>
          ) : (
            item?.name
          )}
          {!editName && (
            <button
              className={styles.editLinks}
              onClick={() => setEditName(true)}
            >
              rename
            </button>
          )}
        </div>
        <div className="flex text-xs mt-3 text-gray-400 dark:text-black">
          {liveScreenshots &&
            (item.capabilities.canTurnOnScreenShots ? (
              <div>
                Show live screenshots
                <input
                  type="checkbox"
                  className="border-gray-300 rounded disabled:bg-gray-300 dark:disabled:bg-zinc-900 text-brandGreen focus:ring-0 ring-opacity-0 border ml-2"
                  onChange={(e) => handleScreenshotChange(e.target.checked)}
                  checked={item.status.screenShotOn}
                />
              </div>
            ) : (
              <>Live screenshots unavaible</>
            ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Sim Management`}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <div className="mb-4">
            <div className="text-xs font-normal mb-1">
              <MdOutlineDragHandle className="size-4  mr-2" />
              <span className="text-gray-500">
                Drag simulator cards to reorder how they appear on the dashboard
              </span>
            </div>
            <div className="text-xs font-normal">
              <FiEdit3 className="size-4 mr-2" />
              <span className="text-gray-500">
                Click edit to rename simulator
              </span>
            </div>
          </div>
          <button
            className="w-full bg-brandYellow text-black py-2 rounded-md mb-4 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={() => handleSaveChanges()}
            disabled={!updateSims}
          >
            Save Changes
          </button>
          {updateFacilitySimulatorsQueryState.isPending ? (
            <div className="w-full h-full min-h-64 bg-white dark:bg-zinc-800 z-50 flex items-center justify-center">
              <SpinnerAnimation color={"#000"} />
            </div>
          ) : (
            <SortableList
              onSortEnd={handleSortEnd}
              className={styles.sortedList}
              draggedItemClassName="draggedItemClass"
            >
              {items.map((item: SimulatorDto) => (
                <SortableItem key={item.id}>
                  <div className={styles.simulatorSortCardContainer}>
                    <SimulatorItem item={item} key={item.id} />
                    <div className={styles.sortGrab}>
                      <SortableKnob key={item.id}>
                        <>
                          <MdOutlineDragHandle className="h-6 w-6 m-0 hover:scale-125" />
                        </>
                      </SortableKnob>
                    </div>
                  </div>
                </SortableItem>
              ))}
            </SortableList>
          )}
        </Row>
      }
    />
  );
};

type SimManagementModalProps = {
  title?: string;
  modalName: string;
};

SimManagementModal.defaultProps = {
  title: "Sim Management",
};

export default SimManagementModal;
