import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  navOverlayAction,
  onlineEventsTrayAction,
} from "ui-modules/nav/navSlice";
import styles from "./Header.styles";
import { LiaAdSolid } from "react-icons/lia";
import LeaguesIcon from "components/svg/LeaguesIcon";
import MarketingIcon from "components/svg/MarketingIcon";
import MenuControlIcon from "components/svg/MenuControlIcon";
import ReservationsIcon from "components/svg/ReservationsIcon";
import StoreIcon from "components/svg/StoreIcon";
import TodayIcon from "components/svg/TodayIcon";
import GearIcon from "components/svg/GearIcon";
import LockerRoomIcon from "components/svg/LockerRoomIcon";

const Menu: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    rexReservations,
    leagueManagement,
    marketingHub,
    events,
    clubhouseAdmin,
    lockerRoom,
    adKit,
  } = useFlags();

  const clubhouseUserClaim = useSelector(
    (state: RootState) => state.user?.clubhouseAdmin
  );

  const isSidebarCollapsed = useSidebarCollapsed();

  const isMenuOverlayOpen = useSelector(
    (state: RootState) => state.nav?.navOverlay?.isOpen
  );

  const isOnlineEventsTrayOpen = useSelector(
    (state: RootState) => state.nav?.onlineEventsTray?.isOpen
  );

  const setMenuOverlay = () =>
    dispatch(navOverlayAction({ isOpen: !isMenuOverlayOpen }));

  const setOnlineEventsTray = () =>
    dispatch(onlineEventsTrayAction({ isOpen: !isOnlineEventsTrayOpen }));

  return (
    <>
      <div
        className={`${
          isSidebarCollapsed ? "hidden" : ""
        } text-xs font-semibold leading-6 brand-light-green`}
      ></div>
      <ul className={styles.menuList}>
        <li key="linkDashboard">
          <NavLink
            to="/dashboard"
            onClick={setMenuOverlay}
            className={styles.menuLink.toString()}
            activeClassName={styles.activeMenuLink.toString()}
          >
            <MenuControlIcon />
            <span
              className={`${styles.menuTitle} ${
                isSidebarCollapsed ? "hidden" : null
              }`}
            >
              Control
            </span>
          </NavLink>
        </li>
        {events && (
          <li key="linkEvents">
            <button
              type="button"
              className={`${styles.menuLink.toString()} w-full`}
              onClick={() => setOnlineEventsTray()}
            >
              <TodayIcon />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Events
              </span>
            </button>
          </li>
        )}
        {rexReservations && (
          <li key="linkReservationsNew">
            <NavLink
              to="/reservations"
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
            >
              <ReservationsIcon aria-hidden="true" />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Reservations
              </span>
            </NavLink>
          </li>
        )}
        {leagueManagement && (
          <li key="linkLeagueManagement">
            <NavLink
              to="/leagues"
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
              prefetch="intent"
            >
              <LeaguesIcon />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Leagues (beta)
              </span>
            </NavLink>
          </li>
        )}
        {clubhouseAdmin && clubhouseUserClaim === true && (
          <li key="linkClubhouseAdmin">
            <NavLink
              to="/admin"
              onClick={setMenuOverlay}
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
              prefetch="intent"
            >
              <GearIcon />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Clubhouse Admin
              </span>
            </NavLink>
          </li>
        )}
        {lockerRoom && (
          <li key="lockerRoom">
            <NavLink
              to="/lockerroom"
              onClick={setMenuOverlay}
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
              prefetch="intent"
            >
              <LockerRoomIcon />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Locker Room
              </span>
            </NavLink>
          </li>
        )}
        {marketingHub && (
          <li key="teamMarketing">
            <NavLink
              to="/marketing"
              onClick={setMenuOverlay}
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
              prefetch="intent"
            >
              <MarketingIcon aria-hidden="true" />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                Marketing Hub
              </span>
            </NavLink>
          </li>
        )}
        {clubhouseUserClaim === true && adKit && (
          <li key="adKit">
            <NavLink
              to="/adkit"
              onClick={setMenuOverlay}
              className={styles.menuLink.toString()}
              activeClassName={styles.activeMenuLink.toString()}
              prefetch="intent"
            >
              <LiaAdSolid />
              <span
                className={`${styles.menuTitle} ${
                  isSidebarCollapsed ? "hidden" : null
                }`}
              >
                AdKit
              </span>
            </NavLink>
          </li>
        )}
        <li key="teamStore">
          <a
            href="https://store.aboutgolf.com"
            className={`${styles.menuLink.toString()} w-full items-center`}
            target="_blank"
            rel="noreferrer"
          >
            <StoreIcon />
            <span
              className={`${styles.menuTitle} ${
                isSidebarCollapsed ? "hidden" : null
              }`}
            >
              <span className="">Store</span>
              <HiOutlineExternalLink className="external text-gray-400 size-2 float-right align-right mr-0 mt-0.5" />
            </span>
          </a>
        </li>
      </ul>
    </>
  );
};

export default Menu;
