import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StepIndicator } from "./step-indicator";
import { clubhouseApiHeadersV2 } from "utils/api/apiHelper";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import {
  Clubhouse_AdKitMetaDataDto,
  Clubhouse_AdKitSamplesDto,
  SimulatorDto,
} from "Models";
import axios from "axios";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import { MultiSelect } from "react-multi-select-component";

const CreateAdkit: React.FC = () => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const [
    adKitMetaData,
    setAdKitMetaData,
  ] = useState<Clubhouse_AdKitSamplesDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<string[]>([
    "3DTeeSign",
    "HoleLoadAd",
    "SponsorAdScorecard",
    "SponsorAd3",
    "SponsorAd2",
    "SponsorAd1",
    "Range Fairway Overlay",
    "Range Tee Overlay",
    "CTP/LD Tee Overlay",
    "Round Tee Overlay",
    "LeaderboardSponsor",
  ]);

  const simulators = useSelector(getSimulatorsByCurrentFacility);

  const [sims, setSims] = useState<string[]>([]);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles([...files, ...Array.from(event.target.files)]);
    }
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocations(
      locations.includes(value)
        ? locations.filter((location) => location !== value)
        : [...locations, value]
    );
  };

  const handleSimChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSims(
      sims.includes(value)
        ? sims.filter((sim) => sim !== value)
        : [...sims, value]
    );
  };

  const StepOne = () => {
    return (
      <div>
        <div className="space-y-2">
          <p className="text-md font-bold">Upload Images</p>
          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            Please upload one or more images to get started
          </p>
        </div>

        <label htmlFor="fileUpload" className="cursor-pointer">
          <div className="flex h-40 w-full flex-col items-center justify-center gap-y-2 border-2 border-dashed rounded-lg bg-gray-50 p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-10 w-10 text-zinc-800 opacity-50"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
              />
            </svg>
            <p className="text-sm text-slate-500">
              Drag and Drop your files here
            </p>
            <p className="text-sm text-slate-500">
              SVG, PNG, JPG or GIF (max. 5MB each)
            </p>
            <input
              type="file"
              id="fileUpload"
              className="hidden"
              multiple
              onChange={handleFileChange}
            />
            <button className="rounded-md bg-brandGreen py-2 px-5 text-xs text-white shadow-xl">
              Browse files
            </button>
          </div>
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-6 mb-6">
          {files.map((file, index) => (
            <div key={index} className="relative group">
              <div className="relative aspect-square w-full overflow-hidden rounded-lg">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index + 1}`}
                  className="object-cover w-full h-full"
                />

                <button
                  onClick={() => {
                    setFiles(files.filter((_, i) => i !== index));
                  }}
                  className="absolute rounded-md top-2 right-2 opacity-100 bg-red-800 transition-opacity"
                >
                  <div className="h-6 w-6 text-white font-bold">&times;</div>
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-between">
          <button
            onClick={handleNext}
            className="bg-brandYellow text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const StepTwo = () => {
    let holes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

    return (
      <div>
        <div className="space-y-2">
          <p className="text-md font-bold">Choose Locations</p>
          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            Select which ad locations you want to place these images
          </p>
        </div>
        <div>
          <div className="grid gap-4 sm:grid-cols-2 2xl:grid-cols-3">
            {adKitMetaData?.adKitMetaDatas.map((meta) => (
              <div className="flex items-center space-x-3 rounded-lg border p-4">
                <div className="flex flex-col">
                  <div className="flex flex-row w-full">
                    <input
                      type="checkbox"
                      aria-hidden="false"
                      id={meta.key}
                      value={meta.id}
                      className="mr-4"
                    />
                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-1 cursor-pointer"
                      htmlFor={meta.key}
                    >
                      {meta.name}
                      <br />
                      <div className="text-xs text-gray-400 dark:text-gray-50">
                        {meta.description}
                      </div>
                    </label>
                  </div>
                  <div className="flex w-full mt-2 ml-6 pl-2">
                    <MultiSelect
                      key={`holes${meta.key}`}
                      options={holes.map((hole) => {
                        return {
                          label: `${hole}`,
                          value: hole,
                        };
                      })}
                      value={holes
                        .filter((hole) => holes?.includes(hole))
                        .map((hole) => {
                          return {
                            label: hole.toString(),
                            value: hole,
                          };
                        })}
                      onChange={(e: { label: string; value: number }[]) =>
                        console.log(e, "holes")
                      }
                      labelledBy="Holes"
                      hasSelectAll={true}
                      selectAllLabel="Select/Unselect All Holes"
                      disableSearch={true}
                      className="dark:text-gray-400 dark:placeholder-black rounded-lg text-xs"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <button
            onClick={handlePrevious}
            className="bg-gray-200 text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="bg-brandYellow text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const StepThree = () => {
    return (
      <div>
        <div className="space-y-2">
          <p className="text-md font-bold">Assign to Simulators</p>
          <p className="text-muted-foreground text-zinc-400 text-xs pb-2">
            Select which simulators you want to assign these images to
          </p>
        </div>
        <div className="h-auto">
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {simulators.map((simulator: SimulatorDto) => (
              <div className="flex items-center space-x-3 rounded-lg border p-4">
                <input
                  type="checkbox"
                  aria-hidden="false"
                  id={simulator.id}
                  value={simulator.id}
                />
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-1 cursor-pointer"
                  htmlFor={simulator.id}
                >
                  {simulator.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <button
            onClick={handlePrevious}
            className="bg-gray-200 text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
          >
            Previous
          </button>
          <button
            onClick={() => alert("Form submitted!")}
            className="bg-brandYellow text-black px-4 py-2 rounded-lg text-sm hover:text-white hover:bg-brandGreen"
          >
            Create AdKit
          </button>
        </div>
      </div>
    );
  };

  function getAdKitMetaData(data: Clubhouse_AdKitMetaDataDto[]) {
    return data.map((item) => ({
      name: item.name,
      description: item.description,
    }));
  }

  useEffect(() => {
    async function fetchAdKitMetaData() {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/ad-kit/metadata`;
      const { data } = await axios.get<Clubhouse_AdKitSamplesDto>(
        url,
        clubhouseApiHeadersV2()
      );

      setAdKitMetaData(data);
      setIsLoading(false);
      pendoTrackBasicEvent(ClubhouseEvents.ADKIT_VIEW);
    }

    if (!adKitMetaData) {
      fetchAdKitMetaData();
    }
  }, [adKitMetaData]);

  return (
    <div>
      <div>
        <StepIndicator
          currentStep={step}
          steps={["Upload Images", "Choose Locations", "Assign to Sims"]}
        />
      </div>
      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
      {step === 3 && <StepThree />}
    </div>
  );
};

export default CreateAdkit;
