import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { AnimatePresence, motion } from "framer-motion";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { useMediaQuery, BreakPoints } from "utils/animations/animationHelper";
import Row from "components/ui/layout/Row";
import CreateNewAdkitModal from "./CreateNewAdkitModal";

const AdKitDashboard = () => {
  const dispatch = useAppDispatch();
  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  const setAdKitModal = () =>
    dispatch(modalAction({ isOpen: true, modalName: ModalType.AdKitModal }));

  const isAdKitModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.AdKitModal]?.isOpen
  );

  return (
    <div
      className={`@container/lockerRoom lockerRoom w-full ${
        isDesktop
          ? isSidebarCollapsed
            ? "pl-24"
            : "pl-64"
          : "px-4 xl:pl-0 pt-8"
      }`}
    >
      {isAdKitModalOpen && (
        <CreateNewAdkitModal modalName={ModalType.AdKitModal} />
      )}
      <Row cssClasses={`flex flex-col md:flex-row min-h-screen w-full mt-4`}>
        <AnimatePresence>
          <motion.div
            className={`w-full my-6 ml-0 sm:mx-8 @container/lockerroom`}
            key="adKitDashboard"
          >
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex min-w-0">
                <div className="flex align-left flex-row items-center">
                  <button
                    onClick={() => setAdKitModal()}
                    className="bg-brandGreen text-white rounded-lg poppins text-xs px-4 py-2"
                  >
                    Create New AdKit
                  </button>
                  <small className="text-xs text-gray-400 dark:text-gray-50 ml-2"></small>
                </div>
              </div>
              <div className="mt-4 flex md:mt-0 md:ml-4 items-center justify-center"></div>
            </div>
            <Row cssClasses="pt-4">
              <div className="flex flex-col rounded-xl border border-zinc-200 dark:border-zinc-900 poppins text-xs">
                <div className="flex flex-row p-2 bg-white dark:bg-zinc-800 text-gray-500 dark:text-gray-50 rounded-xl rounded-br-xl">
                  <div className="flex w-full p-2 text-right items-center justify-end"></div>
                </div>
              </div>
            </Row>
          </motion.div>
        </AnimatePresence>
      </Row>
    </div>
  );
};
export default AdKitDashboard;
